<template>
  <AuthLogin :title="title" :description="description" />
</template>

<script setup lang="ts">
const title = 'Login'
const description = 'Willkommen bei Flowbro. Logge dich mit deinen Anmeldedaten oder mit Social Media ein.'
// Add the Meta Data to the page for SEO and to get a nice preview for social media and messengers

useSeoMeta({
  title: 'Flowbro login',
  ogTitle: 'Flowbro login',
  twitterTitle: 'Flowbro login',
  description,
  ogDescription: description,
  twitterDescription: description,
  ogUrl: useRequestURL().origin,
})
</script>
