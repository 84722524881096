<template>
  <div class="form-container">
    <AuthFlowHeader :title="title" :description="description" class="mb-4" />
    <!-- <AuthSocialAuth /> -->
    <UForm ref="form" :state="state" :schema="schema" class="form mb-2" @submit.prevent>
      <UFormGroup label="E-Mail Adresse" name="email" required>
        <UInput v-model="state.email" placeholder="E-Mail Adresse" icon="i-heroicons-user" />
      </UFormGroup>
      <UFormGroup label="Passwort" name="password" required class="flex flex-col">
        <template #default>
          <UInput v-model="state.password" placeholder="Passwort" type="password" icon="i-heroicons-lock-closed" />
        </template>
        <template #help>
          <NuxtLink to="/forgot-password" class="link mt-0.5 self-end text-xs"> Passwort vergessen? </NuxtLink>
        </template>
      </UFormGroup>
      <UAlert
        v-if="displayError"
        icon="i-heroicons-exclamation-circle"
        color="rose"
        variant="soft"
        title="Fehler beim Login"
        :description="displayError.message"
        :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'rose', variant: 'link', padded: false }"
        @close="displayError = null"
      />
      <UButton block class="mt-10" :loading="loading" @click="submit"> Anmelden </UButton>
    </UForm>
    <div class="text-center">
      <span class="small-text">Kein Account?</span>
      <NuxtLink to="/register" class="link ml-1 text-sm"> Kostenlos neu anmelden </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as Yup from 'yup'

interface Props {
  title: string
  description: string
}

// Props
defineProps<Props>()

const schema = Yup.object({
  email: Yup.string().email('Ungültige E-Mail').required('Dieses Feld bitte ausfüllen'),
  password: Yup.string().min(8, 'Muss mindestens 8 Zeichen lang sein').required('Dieses Feld bitte ausfüllen'),
})

const form = ref()
const state: Ref<UserForm> = ref({
  email: undefined,
  password: undefined,
})

const loading = ref(false)
const displayError: Ref<RestErr | null> = ref(null)

// Validates the form and if the form is valid, submit the input and wait for the login answer
async function submit() {
  // Validate the form in a try catch block
  try {
    await form.value.validate()
  }
  catch (error) {
    console.log('validation error: ', error)
    return
  }

  // Form is validated, now submit the data
  try {
    loading.value = true

    const data = await $fetch<LoginUserResponse>(useApi().buildPath('/auth/login'), {
      method: 'POST',
      body: state.value,
      credentials: 'include',
      onResponseError({ response }) {
        const error = response._data as RestErr

        // Check if it is a password error
        if (error.message.includes('invalid password')) {
          error.message = 'Das Passwort ist falsch.'
        }

        // Check if the user was not found
        if (error.message === 'user not found') {
          error.message = 'Der Benutzer wurde nicht gefunden.'
        }

        throw error
      },
    })
    useAuth().saveLoginData(data)
    // TODO: track login

    if (useAuth().user && !useAuth().user?.organisation) {
      // User needs to create an organisation or accept an invite
      navigateTo('/organisation')
    }
    else {
      // User has an organisation
      navigateTo(`/${useAuth().user?.organisation?.domain}`)
    }
  }
  catch (error) {
    displayError.value = error as RestErr
    console.log('login error: ', error as RestErr)
  }
  loading.value = false
}
</script>
